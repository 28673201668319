import type { ScrollView, FlatList } from 'react-native'
import { Platform } from 'react-native'
import {
  useEffect,
  useCallback,
  ComponentProps,
  RefObject,
  useRef,
} from 'react'
import Router from 'next/router'

const windowScrollMemories: { [asPath: string]: number } = {}
const scrollViewMemories: {
  [asPath: string]: {
    scrollY: number
    isPop: boolean
  }
} = {}

/**
 * The following is taken/edited from `useScrollToTop` from `@react-navigation/native`
 */
type ScrollOptions = { y?: number; animated?: boolean }

type ScrollableView =
  | { scrollToTop(): void }
  | { scrollTo(options: ScrollOptions): void }
  | { scrollToOffset(options: { offset?: number; animated?: boolean }): void }
  | { scrollResponderScrollTo(options: ScrollOptions): void }

type ScrollableWrapper =
  | { getScrollResponder(): React.ReactNode }
  | { getNode(): ScrollableView }
  | ScrollableView

function getScrollableNode(ref: React.RefObject<ScrollableWrapper>) {
  if (ref.current == null) {
    return null
  }

  if (
    'scrollTo' in ref.current ||
    'scrollToOffset' in ref.current ||
    'scrollResponderScrollTo' in ref.current
  ) {
    // This is already a scrollable node.
    return ref.current
  } else if ('getScrollResponder' in ref.current) {
    // If the view is a wrapper like FlatList, SectionList etc.
    // We need to use `getScrollResponder` to get access to the scroll responder
    return ref.current.getScrollResponder()
  } else if ('getNode' in ref.current) {
    // When a `ScrollView` is wraped in `Animated.createAnimatedComponent`
    // we need to use `getNode` to get the ref to the actual scrollview.
    // Note that `getNode` is deprecated in newer versions of react-native
    // this is why we check if we already have a scrollable node above.
    return ref.current.getNode()
  } else {
    return ref.current
  }
}
/**
 * End of react-navigation code.
 */

type OnScroll = NonNullable<
  ComponentProps<typeof ScrollView | typeof FlatList>['onScroll']
>

let isPop = false

/**
 * @param scrollViewRef The `ref` passed to your `ScrollView`.
 */
function useScroller(
  scrollViewRef: RefObject<ScrollableWrapper>,
  {
    scrollDelay: _scrollDelay = 350,
    shouldAnimateScroll: _shouldAnimateScroll = (scrollY) => scrollY < 3000,
  }: {
    /**
     * Number of milliseconds the page should wait before triggering `scrollTo`.
     *
     * This allows content to render before it requires a scroll
     *
     * Default: `350`. Set it to a larger number if you expect a long
     *
     * It also accepts a function that returns a number. The function receives `scrollY`.
     *
     * For example:
     *
     * `scrollDelay = (scrollY) => 350 + scrollY / 10`
     */
    scrollDelay?: number | ((scrollY: number) => number)
    /**
     * Determine whether or not the scroll should animate.
     *
     * You can either pass a `boolean`, or a function which returns a boolean.
     *
     * If you pass a function, it will receive the `scrollY` as its only argument. This lets you determine if it should animate based on how far it's scrolling.
     *
     * By default, this value is `true` as long as the scroll is under 3000px, but you might want to do something like this:
     *
     * `(scrollToY) => scrollToY < 2000`
     *
     * This means your scroll will be animated, as long as the `scrollToY` value is under 2000. If it's too long, it can get a bit choppy with the animation.
     */
    shouldAnimateScroll?: boolean | ((scrollY: number) => boolean)
  } = {}
) {
  const hasScrolled = useRef(false)

  const scrollDelay = useRef(_scrollDelay)
  const shouldAnimateScroll = useRef(_shouldAnimateScroll)
  useEffect(() => {
    shouldAnimateScroll.current = _shouldAnimateScroll
    scrollDelay.current = _scrollDelay
  })

  useEffect(
    function maybeRestoreScroll() {
      if (Platform.OS !== 'web' || typeof window === 'undefined') return

      // de-dupe scrolling more than once on a given mount
      if (hasScrolled.current) return

      // this is mostly taken from react-navigation useScrollToTop
      let timeout = 0
      requestAnimationFrame(() => {
        const path = Router.asPath
        const memory = scrollViewMemories[path]

        console.log('[restore-scroll][maybe-restore-scroll]', { memory, path })

        if (!memory) return

        const { scrollY, isPop } = memory

        if (!isPop || !scrollY) return

        const animated =
          typeof shouldAnimateScroll.current === 'function'
            ? shouldAnimateScroll.current(scrollY)
            : shouldAnimateScroll.current

        const delay =
          typeof scrollDelay.current === 'function'
            ? scrollDelay.current(scrollY)
            : scrollDelay.current

        timeout = setTimeout(() => {
          const scrollable = getScrollableNode(
            scrollViewRef
          ) as ScrollableWrapper

          if ('scrollTo' in scrollable) {
            scrollable.scrollTo({ y: scrollY, animated })

            hasScrolled.current = true
          } else if ('scrollToOffset' in scrollable) {
            scrollable.scrollToOffset({ offset: scrollY, animated })

            hasScrolled.current = true
          } else if ('scrollResponderScrollTo' in scrollable) {
            scrollable.scrollResponderScrollTo({ y: scrollY, animated })

            hasScrolled.current = true
          }

          if (hasScrolled.current) {
            scrollViewMemories[path].isPop = false
          }

          console.log('[restore-scroll][use-scroller] did scroll', {
            hasScrolled: hasScrolled.current,
            scrollY,
            delay,
          })
        }, delay)
      })

      return () => {
        clearTimeout(timeout)
      }
    },
    [scrollViewRef]
  )

  /**
   * Update the scroll position whenever we scroll. This must be passed to your `ScrollView`.
   */
  const onScroll = useCallback<OnScroll>(({ nativeEvent }) => {
    if (Platform.OS === 'web') {
      scrollViewMemories[Router.asPath] = {
        ...scrollViewMemories[Router.asPath],
        scrollY: nativeEvent.contentOffset.y,
      }
    }
  }, [])

  return {
    onScroll,
  }
}

/**
 * This function should be called in `pages/_app.tsx`, outside of render code.
 *
 * https://github.com/vercel/next.js/issues/1309
 * https://github.com/vercel/next.js/issues/1309#issuecomment-690957041
 */
function nextjsScrollPositionRestorer() {
  console.log('[restore-scroll] initialize')
  if (process.browser) {
    window.history.scrollRestoration = 'manual'
    window.onpopstate = () => {
      isPop = true
    }
  }

  Router.events.on('routeChangeStart', () => {
    saveScroll()
  })

  Router.events.on('routeChangeComplete', () => {
    const path = Router.asPath
    if (isPop) {
      restoreWindowScroll()
      setScrollViewPositionPopStatus(path, true)
      isPop = false
    } else {
      setScrollViewPositionPopStatus(path, false)
      // ok, this is a point of contention for me.
      // we're only going to restore the scroll if it's a pop...
      // which is right.
      // but imagine this scenario:
      /**
       * Search screen (scroll down) -> artist profile -> click search screen again.
       *
       * Ok, so in this case, we should *not* restore scroll.
       *
       * However, what if we now do this?
       *
       * go back => profile screen. go back again => search screen.
       *
       * Now, we've popped back, and it SHOULD go back to the search screen.
       *
       * Thus, rather than deleting the scroll value, I think we should do it like this:
       *
       * scrollViewMemories[Router.asPath] = { scrollY, isPop }
       *
       * And if `isPop` is false, then in `useScroller`, we ignore it.
       */
      // deleteScrollViewPosition()
      // replaced with setScrollViewPositionPopStatus
      windowScrollToTop()
    }
  })

  function saveScroll() {
    windowScrollMemories[Router.asPath] = window.scrollY
  }

  function setScrollViewPositionPopStatus(path: string, isPop: boolean) {
    scrollViewMemories[path] = {
      ...scrollViewMemories[path],
      isPop,
    }
  }

  // function deleteScrollViewPosition() {
  //   if (scrollViewMemories[Router.asPath]) {
  //     delete scrollViewMemories[Router.asPath]
  //   }
  // }

  function restoreWindowScroll() {
    const prevWindowScrollY = windowScrollMemories[Router.asPath]
    if (prevWindowScrollY !== undefined) {
      window.requestAnimationFrame(() =>
        setTimeout(window.scrollTo(0, prevWindowScrollY), 150)
      )
    }
  }

  function windowScrollToTop() {
    window.requestAnimationFrame(() => window.scrollTo(0, 0))
  }
}

const ReactNativeNextJsScrollRestore = {
  initialize: nextjsScrollPositionRestorer,
  useScroller,
}

export default ReactNativeNextJsScrollRestore
